var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<dialog id='card' class='border-radius-10 card-modal'>\n    <button class='border-radius-10 flex-centered btn btn-card-modal__exit-wrapper'>\n        <svg\n            class='box-16-16'\n            fill='var(--text-color-in-card)'\n            width='800px'\n            height='800px'\n            viewBox='0 0 16 16'\n            xmlns='http://www.w3.org/2000/svg'\n        >\n            <path\n                d='M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z'\n                fill-rule='evenodd'\n            />\n        </svg>\n    </button>\n    <div class='strach-layout flex-row-centered card-modal__card-data'>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"cardContent") || (depth0 != null ? lookupProperty(depth0,"cardContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cardContent","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"sidebar") || (depth0 != null ? lookupProperty(depth0,"sidebar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sidebar","hash":{},"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</dialog>";
},"useData":true});