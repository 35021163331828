var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button\n    class='box-32-32 flex-centered btn btn-rating btn-rating_CSI'\n    data-rating='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rating") : depth0), depth0))
    + "'\n    data-isActive='false'\n    data-metric='CSI'\n>\n    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rating") : depth0), depth0))
    + "</button>";
},"useData":true});