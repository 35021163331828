var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='strach-layout flex-column-centered page__layout page__layout-board'>\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"header") || (depth0 != null ? lookupProperty(depth0,"header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"header","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":16}}}) : helper))) != null ? stack1 : "")
    + "\n    <div\n        style=\"background: url('/img/"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"thumbnail_url") : depth0), depth0))
    + "'); background-size: cover\"\n        class='flex-row-centered container-board'\n    >\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"sidebar") || (depth0 != null ? lookupProperty(depth0,"sidebar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sidebar","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\n        <div class='flex-column-centered board'>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"boardMenu") || (depth0 != null ? lookupProperty(depth0,"boardMenu") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"boardMenu","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":27}}}) : helper))) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"boardContent") || (depth0 != null ? lookupProperty(depth0,"boardContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"boardContent","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":30}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n</div>";
},"useData":true});