var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button\n    class='box-32-32 flex-centered btn btn-rating btn-rating_NSP'\n    data-rating='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"rating") : depth0), depth0))
    + "'\n    data-isActive='false'\n    data-metric='NSP'\n>\n    <svg\n        xmlns='http://www.w3.org/2000/svg'\n        width='16'\n        height='16'\n        fill='currentColor'\n        class='bi bi-star-fill'\n        viewBox='0 0 16 16'\n    >\n        <path\n            d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'\n        />\n    </svg></button>";
},"useData":true});