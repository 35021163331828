var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a\n    draggable='false'\n    class='strach-layout link link-user-board'\n    href='/workspace/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"workspaceId") : depth0), depth0))
    + "/board/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"boardId") : depth0), depth0))
    + "'\n>\n    <img\n        class='strach-layout border-radius-5 link-user-board__image'\n        src='/img/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"boardImg") : depth0), depth0))
    + "'\n        alt=''\n        draggable='false'\n    />\n    <span class='font-size-16 font-weight-500 workspace-board__title'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"boardName") : depth0), depth0))
    + "</span>\n</a>\n";
},"useData":true});