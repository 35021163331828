var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class='workspaces-list__item-wrapper'>\n    <div\n        class='font-size-24 font-weight-bold flex-row-centered gap-10 padding-0-10 workspaces-list__item'\n    ><span class='box-32-32 border-radius-5 flex-centered workspace-logo'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"paragraph") : depth0), depth0))
    + "</span>\n        <span\n            data-paragraph=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"workspaceId") : depth0), depth0))
    + "\"\n            class='font-size-16 font-weight-500 workspace-paragraph'\n        >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n    </div>\n</li>";
},"useData":true});