var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(blockParams[0][0], depth0)) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class='box-32-32 flex-row-centered team-member__remain'>\n                    <button\n                        class='font-size-18 font-weight-500 strach-layout flex-centered btn btn-team-member__remain'\n                    >\n                        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"usersOveflow") : depth0)) != null ? lookupProperty(stack1,"count") : stack1), depth0))
    + "\n                    </button>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-row-centered board__menu'>\n    <div class='flex-row-centered gap-50 board__menu_left'>\n        <div class='flex-row-centered gap-10 board-menu__board-name-wrapper'>\n            <img\n                class='box-24-24 add-board-to-favourite'\n                src='/svg/board.svg'\n                alt='add-to-favourite'\n            />\n            <div class='flex-centered board-name__input-wrapper' data-active='false'>\n                <span\n                    contenteditable='true'\n                    class='border-radius-5 padding-5-10 font-size-22 font-weight-500 board-name__input'\n                    data-board='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"boardID") : depth0), depth0))
    + "'\n                >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n            </div>\n        </div>\n    </div>\n    <div class='flex-row-centered gap-20 board__menu_right'>\n        <div class='flex-row-centered gap-5 board-menu__team'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"users") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":37,"column":12},"end":{"line":39,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"usersOveflow") : depth0)) != null ? lookupProperty(stack1,"overflow") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":40,"column":12},"end":{"line":48,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class='flex-row-centered gap-10 board-menu__board-actions'>\n            <button\n                class='flex-row-centered border-radius-5 gap-10 padding-5-10 btn btn-filter-action'\n                disabled\n            >\n                <svg\n                    xmlns='http://www.w3.org/2000/svg'\n                    width='16'\n                    height='16'\n                    fill='#000000'\n                    class='box-24-24 strach-layout board-actions__image'\n                    viewBox='0 0 16 16'\n                >\n                    <path\n                        d='M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z'\n                    />\n                </svg>\n                <span class='font-size-16 font-weight-500 board-actions__title'>Сбросить фильтры</span>\n            </button>\n        </div>\n    </div>\n</div>";
},"useData":true,"useBlockParams":true});