var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "            "
    + ((stack1 = container.lambda(blockParams[0][0], depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"flex-column-centered gap-20 container-profile__user-information\">\n    <form\n        id='form-profile'\n        class='form form-profile'\n        action='/user/edit/'\n        method='POST'\n        enctype='application/x-www-form-urlencoded'\n    >\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"inputs") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":17}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"textarea") || (depth0 != null ? lookupProperty(depth0,"textarea") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"textarea","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"btn") || (depth0 != null ? lookupProperty(depth0,"btn") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btn","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":17}}}) : helper))) != null ? stack1 : "")
    + "\n    </form>\n</div>";
},"useData":true,"useBlockParams":true});