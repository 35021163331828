var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='container-profile__avatar'>\n    <div class='profile-avatar'>\n        <div class='profile-avatar__image'>\n            <div class='profile-avatar__change-avatar'><div class='change-avatar__wrapper'>\n                    <button data-open='true' class='change-avatar__button'>\n                        <img\n                            class='change-avatar__icon'\n                            src='/svg/camera.svg'\n                            alt='change-avatar'\n                        /></button>\n                </div>\n            </div>\n            <img class='avatar profile-user-image' src='/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "' alt='avatar' />\n        </div>\n        <div class='profile-avatar__user-reference'>\n            <span class='font-size-25 font-weight-500 user-reference__name'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n            <span class='font-size-25 font-weight-500 user-reference__surname'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"surname") : depth0), depth0))
    + "</span>\n            <span class='user-reference__email'><b>@</b>&nbsp"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</span>\n        </div>\n    </div>\n</div>";
},"useData":true});