var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input\n            data-id='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "'\n            class='box-16-16 add-card-user__select-user'\n            type='checkbox'\n            checked\n        />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input data-id='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "' class='box-16-16 add-card-user__select-user' type='checkbox' />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-row-centered gap-10 border-radius-5 add-card-user__user'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"inCard") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "    <div class='flex-row-centered gap-10 add-card-user__user-information'><img\n            class='avatar box-32-32 add-card-user__user-avatar'\n            src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "'\n            alt='avatar'\n        /><span class='font-size-16 font-weight-500 add-card-user__user-email'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</span>\n    </div>\n</div>";
},"useData":true});