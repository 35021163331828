var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-column-centered flex-centered strach-layout gap-20 page__layout page__layout-nof-found'>\n    <div class='font-size-36 font-weight-bold not-found__title'>Ошибка 404 :(</div>\n    <div class='font-size-16 font-weight-500 not-found__message'>Такой страницы не существует</div>\n    <div class='not-found__image'><img src='/img/BMO.png' alt='Not found' /></div>\n    <button class='font-size-24 font-weight-500 border-radius-10 padding-5-10 btn btn-not-found'>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"redirectionPage") : depth0), depth0))
    + "</button>\n</div>";
},"useData":true});