var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-column-centered strach-layout page__layout page__layout-main'>\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"header") || (depth0 != null ? lookupProperty(depth0,"header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"header","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":16}}}) : helper))) != null ? stack1 : "")
    + "\n    <div class='flex-row-centered container-main'>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"sidebar") || (depth0 != null ? lookupProperty(depth0,"sidebar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sidebar","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\n        <div class='flex-column-centered gap-50 user-workspaces'>\n            <div class='flex-column-centered gap-20 workspaces'>\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"userWorkspaces") || (depth0 != null ? lookupProperty(depth0,"userWorkspaces") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userWorkspaces","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});