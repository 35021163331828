var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class='flex-start padding-0-10 border-radius-5 navigation__item'>\n                    <a\n                        class='link profile-link'\n                        data-action='"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"action") : stack1), depth0))
    + "'\n                        href='"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"href") : stack1), depth0))
    + "'\n                    >"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</a>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<dialog id='navigation-menu' class='border-radius-10 pop-up-menu' aria-modal='false'>\n    <div class='flex-column-centered gap-15 padding-10-15 strach-layout pop-up-menu-wrapper'>\n        <div class='flex-column-centered flex-centered gap-5 pop-up-menu__user'>\n            <span class='font-size-15 font-weight-500 pop-up-menu__menu-title'>Учётная запись</span>\n            <div class='flex-row-centered padding-0-10 flex-centered pop-up-menu__user-profile'>\n                <div class='box-40-40 pop-up-menu__user-avatar'>\n                    <img\n                        class='avatar avatar-wrapper pop-up-menu__avatar-image'\n                        src='/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "'\n                        alt='avatar'\n                    />\n                </div>\n                <div class='flex-column-centered flex-start gap-5 pop-up-menu__user-information'>\n                    <span\n                        class='font-size-14 font-weight-400 user-information__nickname'\n                    >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "&nbsp"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"surname") : depth0), depth0))
    + "</span><span\n                        class='font-size-14 font-weight-100 user-information__email'\n                    >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class='pop-up-menu__underline'></div>\n        <div class='flex-column-centered flex-centered gap-5 pop-up-menu__navigation'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":24,"column":12},"end":{"line":32,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n        <div class='pop-up-menu__underline'></div>\n        <div class='flex-column-centered flex-start pop-up-menu__log-out'>\n            <div class='flex-start border-raduis-5 padding-0-10 navigation__item'>\n                <a class='link profile-link' data-action='logout' href='/logout'>Выход</a>\n            </div>\n        </div>\n    </div>\n</dialog>";
},"useData":true,"useBlockParams":true});