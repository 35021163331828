var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class='font-size-15 flex-start border-radius-5 padding-0-10 navigation__item'>\n                <button\n                    data-open='true'\n                    class='btn font-size-15 btn-profile'\n                    data-action='"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"action") : stack1), depth0))
    + "'\n                ><span>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</span></button>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<dialog id='change-avatar' class='border-radius-10 pop-up-change-avatar' aria-modal='false'>\n    <div\n        class='flex-centered flex-column-centered strach-layout padding-5-10 pop-up-change-avatar-wrapper'\n    >\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":5,"column":8},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</dialog>";
},"useData":true,"useBlockParams":true});