var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<dialog id='card-file' class='padding-10-20 border-radius-10 card-file'>\n    <div class='flex-column-centered gap-30 card-file__content'>\n        <span class='font-size-18 font-weight-500 card-file__title'>Вложения</span>\n        <span\n            style='display: none;'\n            class='font-size-16 font-weight-500 card-file__filename'\n        ></span>\n        <form\n            id='upload-card-file'\n            class='flex-cetered gap-10 upload-card-file'\n            enctype='multipart/form-data'\n            method='POST'\n            action='/task/file/attach/'\n        >\n            <button\n                type='button'\n                data-action='choose'\n                class='btn btn-upload-file padding-5-10 border-radius-5 font-size-16 font-weight-bold'\n                form='upload-card-file'\n            >\n                <span>Загрузите файл<input\n                        type='file'\n                        accept='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"fileTypes") : depth0), depth0))
    + "'\n                        class='input input-upload-file'\n                        name='user-file'\n                    /></span>\n            </button>\n        </form>\n        <div class='flex-column-centered gap-10 card-file__buttons'>\n            <button\n                class='border-radius-5 padding-5-10 font-size-16 font-weight-500 btn btn-attach-file_upload'\n            >Прикрепить</button><button\n                class='border-radius-5 padding-5-10 font-size-16 font-weight-500 btn btn-attach-file_cancel'\n            >Отмена</button>\n        </div>\n    </div>\n</dialog>";
},"useData":true});