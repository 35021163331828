var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(blockParams[0][0], depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class='list' data-list='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "' data-list_position='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"position") : depth0), depth0))
    + "'>\n    <div class='flex-column-centered border-radius-10 gap-10 list__container' draggable='true'>\n        <div class='flex-row-centered list__title-wrapper'>\n            <span\n                data-list='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "'\n                contenteditable='true'\n                class='font-size-20 font-weight-500 border-radius-5 padding-5-10 list__title'\n            >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n            <button class='box-24-24 flex-centered btn btn-change-list' data-list='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "'>\n                <img\n                    class='strach-layout list-action__image'\n                    src='/svg/three-dots.svg'\n                    alt='list-action'\n                />\n            </button>\n        </div>\n        <ol class='flex-column-centered padding-0-5 gap-10 list__content'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"cards") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":18,"column":12},"end":{"line":20,"column":21}}})) != null ? stack1 : "")
    + "        </ol>\n        <div data-entity='card' class='padding-10-15 add-new-card'>\n            <button class='flex-row-centered gap-10 btn btn-add-new-card add-new-entity'>\n                <img class='box-16-16 add-card__image' src='/svg/plus.svg' alt='add-card' />\n                <span class='font-size-16 font-weight-500 add-card__title'>Добавить карточку</span>\n            </button>\n        </div>\n        <div data-entity='card' class='new-entity new-card' style='display: none'>\n            <form\n                class='flex-column-centered gap-10 border-radius-10 padding-5-10 form form-new-card__container'\n                id='create-card__list-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "'\n                action='/card/create/'\n                method='POST'\n            >\n                <input\n                    maxlength='32'\n                    type='text'\n                    class='font-size-16 font-weght-200 border-radius-5 padding-5-10 input input-new-card-name'\n                    placeholder='Название карточки'\n                    value=''\n                    required\n                />\n                <div class='flex-row-centered gap-10 new-card__buttons'>\n                    <button\n                        class='border-radius-5 font-size-16 font-weight-500 flex-centered gap-10 padding-0-10 btn btn-create-card btn-create_confirm'\n                        disabled\n                    >\n                        Добавить карточку\n                    </button>\n                    <button\n                        class='box-32-32 flex-centered border-radius-5 gap-10 btn btn-create-card btn-create_cancel'\n                    >\n                        <svg\n                            class='btn-add-card-cancel__image border-radius-5 box-16-16'\n                            fill='#c0c9f4'\n                            width='800px'\n                            height='800px'\n                            viewBox='0 0 16 16'\n                            xmlns='http://www.w3.org/2000/svg'\n                        >\n                            <path\n                                d='M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z'\n                                fill-rule='evenodd'\n                            />\n                        </svg></button>\n                </div>\n            </form></div>\n    </div>\n</li>";
},"useData":true,"useBlockParams":true});