var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <a\n                    class='profile-navigation__user-information profile-navigation_active'\n                    href='/profile'\n                >Профиль</a>\n                <a class='profile-navigation__security' href='/security'>Безопасность</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <a class='profile-navigation__user-information' href='/profile'>Профиль</a>\n                <a\n                    class='profile-navigation__security profile-navigation_active'\n                    href='/security'\n                >Безопасность</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-column-centered strach-layout page__layout page__layout-profile'>\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"header") || (depth0 != null ? lookupProperty(depth0,"header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"header","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":16}}}) : helper))) != null ? stack1 : "")
    + "\n    <div class='container-profile'>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"userAvatar") || (depth0 != null ? lookupProperty(depth0,"userAvatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userAvatar","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":24}}}) : helper))) != null ? stack1 : "")
    + "\n        <nav class='container-profile__navigation'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"information") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "        </nav>\n        <div class='profile-separator-line'></div>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"userProfile") || (depth0 != null ? lookupProperty(depth0,"userProfile") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userProfile","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>";
},"useData":true});