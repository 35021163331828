var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-column-centered gap-5 card-information__comment'>\n    <img\n        src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "'\n        alt='avatar'\n        class='avatar box-32-32 card-information__comment-avatar'\n    />\n    <span\n        class='font-size-12 font-weight-500 card-information__user-information'\n    >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</span>\n    <textarea\n        class='font-size-12 font-weight-200 padding-5-10 border-radius-5 card-information__comment-text'\n        disabled\n    >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"comment") : depth0), depth0))
    + "</textarea>\n</div>";
},"useData":true});