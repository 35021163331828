var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a\n    href='/workspace/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"workspaceID") : depth0), depth0))
    + "/board/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"boardID") : depth0), depth0))
    + "'\n    class='flex-row-centered border-radius-5 gap-10 padding-5-10 link link-sidebar-boards-list__board'\n    data-board='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"boardID") : depth0), depth0))
    + "'\n>\n    <img\n        class='border-radius-5 sidebar-boards-list__board-image'\n        src='/img/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"thumbnail_url") : depth0), depth0))
    + "'\n        alt='board-image'\n    />\n    <span class='sidebar-boards-list__board-name'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n</a>";
},"useData":true});