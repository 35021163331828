var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class='history__item-wrapper'>\n    <div class='flex-row-centered gap-10 padding-5-10 history__item'>\n        <div class='box-32-32 flex-centered  history__user'>\n            <img src='/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "' alt='avatar' class='strach-layout avatar-wrapper' />\n        </div>\n        <div class='flex-column-centered gap-10 history-item__content-wrapper'>\n            <span\n                class='font-size-14 font-weight-500 history-item__user-email'\n            >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</span>\n            <span\n                class='font-size-14 font-weight-200 history-item__action-message'\n            >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</span>\n            <span\n                class='font-size-14 font-weight-300 history-item__action-datetime'\n            >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"creationDate") : depth0), depth0))
    + "</span>\n        </div>\n    </div>\n</li>";
},"useData":true});