var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button\n    data-tag='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tagName") : depth0), depth0))
    + "'\n    class='flex-centered border-radius-4 padding-0-10 btn-list-card__tag'\n><span class='font-weight-500 font-size-12 list-card-tag__text'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tagName") : depth0), depth0))
    + "</span>\n</button>";
},"useData":true});