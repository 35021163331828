var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-row-centered gap-20 border-radius-5 card-information__file-wrapper'>\n    <a\n        draggable='false'\n        href='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "'\n        target='_blank'\n        class='flex-centered border-radius-5 file-wrapper__background'\n        download='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"file") : depth0), depth0))
    + "'\n    ><span\n            class='font-size-22 font-weight-bold file-wrapper__filename'\n        >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fileExtension") : depth0), depth0))
    + "</span>\n    </a>\n    <div class='flex-column-centered gap-15 card-information__file-description-wrapper'>\n        <div class='flex-centered card-information_filename-wrapper'>\n            <a\n                draggable='false'\n                class='flex-row-centered gap-5 border-radius-5 card-file-download'\n                href='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "'\n                target='_blank'\n                download='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "'\n            >\n                <span\n                    class='font-size-14 font-weight-500 card-information__filename'\n                >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"filename") : depth0), depth0))
    + "</span>\n                <svg\n                    width='800px'\n                    height='800px'\n                    viewBox='0 0 24 24'\n                    fill=''\n                    class='box-24-24'\n                    xmlns='http://www.w3.org/2000/svg'\n                >\n                    <g id='Arrow / Arrow_Up_Right_SM'>\n                        <path\n                            id='Vector'\n                            d='M8 16L16 8M16 8H10M16 8V14'\n                            stroke='#535e6b'\n                            stroke-width='2'\n                            stroke-linecap='round'\n                            stroke-linejoin='round'\n                        />\n                    </g>\n                </svg>\n            </a>\n        </div>\n        <span class='font-size-14 font-weight-200 card-information__file-added-date'>\n            Добавлено\n            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"creationDate") : depth0), depth0))
    + "\n        </span>\n        <div class='flex-row-centered gap-20 card-information__file-actions'>\n            <button\n                class='padding-5-10 font-size-16 font-weight-500 border-radius-5 btn btn-file-actions_delete'\n            >\n                Удалить\n            </button>\n        </div>\n    </div>\n</div>";
},"useData":true});