var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button\n                data-workspace='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"workspaceId") : depth0), depth0))
    + "'\n                class='border-radius-5 btn btn-change-workspace'\n            ><div\n                    class='strach-layout flex-row-centered padding-5-10 gap-10 border-radius-5 btn-change-workspace__contant-wrapper'\n                >\n                    <img\n                        src='/svg/gear.svg'\n                        class='box-24-24 flex-centered btn-change-workspace__image'\n                    />\n                    <span\n                        class='flex-centered font-weight-bold font-size-16 btn-change-workspace__title'\n                    >Действия</span>\n                </div>\n            </button>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "            "
    + ((stack1 = container.lambda(blockParams[0][0], depth0)) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button\n                data-workspace='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"workspaceId") : depth0), depth0))
    + "'\n                class='strach-layout border-radius-5 flex-centered btn btn-create-board'\n            >\n                <span\n                    class='flex-centered strach-layout font-size-16 font-weight-500 workspace-board__create-title'\n                >Создать доску</span>\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-column-centered gap-10 workspace'>\n    <div class='flex-row-centered workspace__menu'><span\n            class='font-size-18 font-weight-500 padding-0-10 gap-10 flex-row-centered workspace__title'\n        >\n            <span\n                class='box-32-32 border-radius-5 flex-centered workspace-logo'\n            >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"workspaceIcon") : depth0), depth0))
    + "</span>\n            <span\n                contenteditable='true'\n                data-workspace='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"workspaceId") : depth0), depth0))
    + "'\n                class='font-weight-bold padding-5-10 border-radius-5 workspace__name'\n            >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"workspaceName") : depth0), depth0))
    + "</span>\n        </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isOwner") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":14,"column":8},"end":{"line":30,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class='flex-row-centered gap-10 workspace__boards'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"boards") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":33,"column":8},"end":{"line":35,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isOwner") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":36,"column":8},"end":{"line":45,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true,"useBlockParams":true});