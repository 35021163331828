var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button\n                    class='border-radius-5 flex-centered btn btn-create-board btn-board-sidebar'\n                    data-workspace='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"workspaceID") : depth0), depth0))
    + "'\n                >\n                    <img\n                        class='sidebar-boards-list__action-image'\n                        src='/svg/plus.svg'\n                        alt='additional-action'\n                    />\n                </button>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "                    "
    + ((stack1 = container.lambda(blockParams[0][0], depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-column-centered gap-15 sidebar'>\n    <div\n        class='flex-row-centered flex-centered gap-15 padding-5-10 sidebar__workspace-information'\n        data-workspace='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"workspaceID") : depth0), depth0))
    + "'\n    >\n        <span\n            class='box-32-32 font-size-22 font-weight-bold border-radius-5 flex-centered workspace-logo'\n        >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"workspaceIcon") : depth0), depth0))
    + "</span>\n        <span\n            class='font-size-22 font-weight-500 sidebar__workspace-name'\n        >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"workspaceName") : depth0), depth0))
    + "</span>\n    </div>\n    <div class='flex-column-centered gap-15 sidebar__actions'>\n        <div class='flex-row-centered sidebar__board-members'>\n            <div class='flex-row-centered gap-10 board-action__main-action'>\n                <img\n                    class='box-24-24 board-action__image'\n                    src='/svg/person.svg'\n                    alt='board-action-image'\n                /><span class='board-action__name'>Участники</span>\n            </div>\n            <button\n                class='border-radius-5 flex-centered btn btn-add-user-to-board btn-board-sidebar'\n            >\n                <img class='additional-action__image' src='/svg/plus.svg' alt='additional-action' />\n            </button>\n        </div>\n        <div class='flex-row-centered gap-10 sidebar__board-settings'>\n            <div class='flex-row-centered gap-10 board-action__main-action'>\n                <img\n                    class='box-24-24 board-action__image'\n                    src='/svg/gear.svg'\n                    alt='board-action-image'\n                /><span class='board-action__name'>Настройки доски</span>\n            </div>\n            <button class='flex-centered btn border-radius-5 btn-board-sidebar btn-board-settings'>\n                <img\n                    class='box-24-24 strach-layout additional-action__image'\n                    src='/svg/three-dots.svg'\n                    alt='board-actions'\n                />\n            </button>\n        </div>\n    </div>\n    <div class='flex-column-centered gap-15 sidebar__boards-list'>\n        <div class='flex-row-centered sidebar-boards-list__action'>\n            <span class='font-size-18 font-weight-500 gap-10 sidebar-boards-list__action-title'>Доски</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isOwner") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":48,"column":12},"end":{"line":59,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class='sidebar-board-list-wrapper'>\n            <div class='flex-column-centered gap-5 sidebar-board-list'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"boards") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":63,"column":16},"end":{"line":65,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>";
},"useData":true,"useBlockParams":true});