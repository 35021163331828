var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "            "
    + ((stack1 = container.lambda(blockParams[0][0], depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n    data-checklist='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "'\n    class='flex-column-centered gap-15 card-information__checklist-wrapper'\n>\n    <div class='flex-row-centered checklist-wrapper__title'>\n        <img class='box-24-24 card-information__icon' src='/svg/checklist.svg' />\n        <span\n            class='font-size-16 font-weight-500 card-information__card-checklist-title'\n        >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n        </span>\n        <button\n            class='padding-5-10 border-radius-5 font-size-16 font-weight-500 btn btn-delete-checklist'\n        >Удалить</button>\n    </div>\n    <div class='flex-column-centered gap-5 card-information__checkitems'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"checklistItems") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":16,"column":8},"end":{"line":18,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class='flex-column-centered checklist-add-item'>\n        <button\n            class='padding-5-10 border-radius-5 font-size-14 font-weight-500 btn btn-add-checklist-element'\n        >Добавить элемент</button>\n        <div class='flex-column-centered gap-10 checklist-new-item'>\n            <input\n                placeholder='Название пункта чеклиста...'\n                maxlength='128'\n                class='border-radius-5 font-size-14 font-weight-500 padding-5-10 input input-checklist-new-item__input'\n                type='text'\n            />\n            <div class='flex-row-centered gap-10 checklist-new-item__buttons'>\n                <button\n                    class='padding-5-10 border-radius-5 font-size-14 font-weight-500 btn btn-checklist-new-item_save'\n                >Сохранить</button>\n                <button\n                    class='padding-5-10 border-radius-5 font-size-14 font-weight-500 btn btn-checklist-new-item_cancel'\n                >Отмена</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true,"useBlockParams":true});