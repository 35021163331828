var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <input\n            type='checkbox'\n            class='box-16-16 border-radius-5 input input-checkitem__checkbox'\n            checked\n        />\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <input\n            type='checkbox'\n            class='box-16-16 border-radius-5 input input-checkitem__checkbox'\n        />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n    data-checkitem_id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"checkitem_id") : depth0), depth0))
    + "'\n    data-checklist_id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"checklist_id") : depth0), depth0))
    + "'\n    class='padding-5-10 border-radius-5 flex-row-centered checkitem'\n    draggable=\"true\"\n>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "    <div class='flex-centered checkitem__name'><span\n            class='font-size-14 font-weight-500 checkitem-name__text'\n        >"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span></div>\n    <button class='box-12-12 border-radius-5 flex-centered btn btn-checkitem_delete'>\n        <svg\n            class='box-12-12'\n            fill='var(--text-color-in-card)'\n            width='800px'\n            height='800px'\n            viewBox='0 0 16 16'\n            xmlns='http://www.w3.org/2000/svg'\n        >\n            <path\n                d='M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z'\n                fill-rule='evenodd'\n            />\n        </svg>\n    </button>\n</div>";
},"useData":true});