var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='page page__layout-questionnaire flex-centered'>\n    <div class='border-radius-10 flex-column-centered gap-30 questionnaire'>\n        <button class='box-16-16 border-radius-5 btn btn-close-iframe'><img\n                class='strach-layout btn-close-iframe__image'\n                src='/svg/close.svg'\n                alt='close'\n            /></button>\n        <div class='flex-column-centered gap-30 questionnaire__content'>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"content","hash":{},"data":data,"loc":{"start":{"line":8,"column":72},"end":{"line":8,"column":85}}}) : helper))) != null ? stack1 : "")
    + "</div>\n        <div class='flex-row-centered questionnaire__navigation'>\n            <button\n                class='font-size-16 font-weight-500 flex-centered btn btn-questionnaire-navigation btn-questionnaire-navigation_next'\n            >\n                Далее\n            </button>\n            <button\n                class='font-size-16 font-weight-500 flex-centered btn btn-questionnaire-navigation btn-questionnaire-navigation_skip'\n            >\n                Пропустить\n            </button>\n        </div>\n    </div>\n</div>";
},"useData":true});