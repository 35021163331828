var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <button\n                class='border-radius-5 flex-centered font-size-16 font-weight-bold padding-5-10 btn btn-delete-board'\n            >\n                Удалить доску\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<dialog\n    id='board-settings'\n    class='border-radius-10 board-settings'\n    aria-modal='false'\n    data-board='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "'\n    style='top: 0px; left: 0px'\n>\n    <div class='flex-column-centered gap-5 padding-10-15 board-settings-wrapper'>\n        <button\n            class='border-radius-5 flex-centered font-size-16 font-weight-bold border-radius-5 padding-5-10 btn btn-change-board-name'\n        >\n            Изменить название доски\n        </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isOwner") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</dialog>";
},"useData":true});