var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='flex-column-centered flex-centered strach-layout page__layout page__layout-sign'>\n    <img class='page__image page__image_left' src='/svg/undraw_creative_woman.svg' />\n    <img class='page__image page__image_right' src='/svg/undraw_meet_the_team.svg' />\n    <div class='flex-column-centered flex-centered gap-25 container-sign'>\n        <h1 class='flex-centered flex-row-centered container-sign__header'>\n            <img class='container-sign-header__logo' src='/svg/logo.svg' />\n            <span class='container-sign-header__title'>Tabula</span>\n        </h1>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"form") || (depth0 != null ? lookupProperty(depth0,"form") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"form","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":18}}}) : helper))) != null ? stack1 : "")
    + "\n        <div class='flex-column-centered gap-10'>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"signupLink") || (depth0 != null ? lookupProperty(depth0,"signupLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"signupLink","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":28}}}) : helper))) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"loginBtn") || (depth0 != null ? lookupProperty(depth0,"loginBtn") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"loginBtn","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":26}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n</div>";
},"useData":true});